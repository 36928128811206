@import 'app-framework';
@import 'app-main';

.sidebar-left, .navbar-default, .no-print, .logo-trib, footer{
	display: none !important;
}

.contain{
	width: 92% !important;
	position: absolute;
	left: 0;
	margin: 4% !important;
}

table{
	width: 100% !important;
}

.table-bordered {
	border: 1px solid #ccc !important;
}

table tbody tr th{
	background-color: #003366 !important;
	vertical-align: middle !important;
}

table tbody tr th em, table tbody tr th span{
	color: #FFFFFF !important;
}

table .table .table-striped{
	display: block !important;
}