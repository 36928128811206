.vertical-align {
  display: flex;
  flex-direction: row;
}

.vertical-align > [class^="col-"],
.vertical-align > [class*=" col-"] {
  margin: auto auto;
  text-align: center;
}

.img-responsive {
  margin: 0 auto;
}

.full-height {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: table;
  height: 100%;
  padding: 0;
  width: 100%;

  & > .row {
    display: table-row;
    height: 100%;

    & > [class^="col-"], & > [class*=" col-"] {
      float: none;
      display: table-cell;
      height: 100%;
      vertical-align: top;
    }
  }
}

a.icon[href$='.xls'], a.icon[href$='.xlsx'], a.icon[href$='.xlt'], a.icon[href$='.xltx'], 
a.icon[href$='.XLS'], a.icon[href$='.XLSX'], a.icon[href$='.XLT'], a.icon[href$='.XLTX'],
a.icon[data-formato='xls'], a.icon[data-formato='xlsx'], a.icon[data-formato='xlt'], a.icon[data-formato='xltx'], 
a.icon[data-formato='XLS'], a.icon[data-formato='XLSX'], a.icon[data-formato='XLT'], a.icon[data-formato='XLTX']{
  padding: 5px 0 5px 23px;
  background: transparent url('/default/img/default_icon/xls.gif') no-repeat center left;
}

a.icon[href$='.pdf'], a.icon[href$='.PDF'],
a.icon[data-formato='pdf'], a.icon[data-formato='PDF'] {
  padding: 5px 0 5px 23px;
  background: transparent url('/default/img/default_icon/pdf.gif') no-repeat center left;
}

a.icon[href$='.doc'], a.icon[href$='.docx'], a.icon[href$='.DOC'], a.icon[href$='.DOCX'],
a.icon[data-formato='doc'], a.icon[data-formato='docx'], a.icon[data-formato='DOC'], a.icon[data-formato='DOCX'] {
  padding: 5px 0 5px 23px;
  background: transparent url('/default/img/default_icon/doc.gif') no-repeat center left;
}

a.icon[href ^="mailto:"] {
  padding: 5px 0 5px 23px;
  background: transparent url('/default/img/default_icon/mailto.gif') no-repeat center left;
}

a.icon[href$='.zip'], a.icon[href$='.rar'], a.icon[href$='.ZIP'], a.icon[href$='.RAR'],
a.icon[data-formato='zip'], a.icon[data-formato='rar'], a.icon[data-formato='ZIP'], a.icon[data-formato='RAR'] {
  padding: 5px 0 5px 23px;
  background: transparent url('/default/img/default_icon/archivio.gif') no-repeat center left;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;

  address,
  article,
  aside,
  blockquote,
  canvas,
  dd,
  dl,
  dt,
  fieldset,
  figcaption,
  figure,
  footer,
  form,
  h1, h2, h3, h4, h5, h6,
  header,
  hr,
  //li,
  main,
  nav,
  noscript,
  ol,
  p,
  pre,
  section,
  table,
  tfoot,
  ul,
  video,
  .row
  {
    width: 100%;
  }

  hr{
    border: 1px inset;
  }
  ul:not(.list-unstyled) {
    list-style-type: square;
  }
  ul li {
    text-align: justify;
    margin-left: 0px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: solid 1px #cecece;
  }
}

.fieldset{
  order: 2;
  padding: 0.75em;
  min-inline-size: min-content;
  border: 2px groove threedface;

  a{
    text-decoration: underline;
  }
}

.elenco li {
  list-style-type: square;
  text-align: justify;
  margin-left: 0px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: solid 1px #cecece;
}


@media screen and (max-width: 600px) {
  .mticket-10{
      margin-top: 10px;
  }
}